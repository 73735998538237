.marquee {
  text-align: left;
  overflow: hidden;
}

.marquee-overflow {
  width: 1000vw;
  white-space: nowrap;
}

.marquee-text {
  display: inline-block;
  line-height: 1.25em;
  animation: marquee 10s linear infinite running;
  transform: translate(0%);
  padding: 0;
  margin: 0;
}

.marquee.slow .marquee-text {
  animation-duration: 40s;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
